import { ChevronRight } from "@mui/icons-material";
import classNames from "classnames";
import useActiveMenu from "hooks/useActiveMenu";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getParentId } from "./functions";
import CheckPermission from "../../components/CheckPermission";

function MenuItem({ data, collapseMenu, className, menuActive, parentId }) {
  const [isExpanded, setExpanded] = useState(false);
  const [isShowCollapse, setIsShowCollapse] = useState(true);
  const collapseRef = useRef();
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const paramArray = Object.keys(params).map((key) => params[key]);
  const [...p] = paramArray;

  useEffect(() => {
    if (collapseRef.current) {
      const elementWithClassName =
        collapseRef.current.querySelector(".menuItem");
      if (elementWithClassName) {
        setIsShowCollapse(true);
      } else {
        setIsShowCollapse(false);
      }
    }
  }, []);

  const onChangeItem = useCallback(
    (link) => {
      if (typeof link === "function" && paramArray?.length) {
        const url = link(...p);
        history.push(url);
      } else {
        history.push(link);
      }
    },
    [history]
  );

  const isActiveParent = useMemo(() => {
    return parentId === data?.id;
  }, [parentId, data?.id]);
  useEffect(() => {
    if (parentId === data?.id && !isExpanded) {
      setExpanded(true);
    }
  }, [isExpanded, parentId, data?.id]);

  const render = useCallback(
    (data) => {
      switch (data?.type) {
        case "collapse":
          return (
            <div
              ref={collapseRef}
              style={{ display: isShowCollapse ? "" : "none" }}
            >
              <div
                key={data?.id}
                onClick={() => setExpanded(!isExpanded)}
                className={classNames(
                  "flex items-center gap-12px cursor-pointer cyan relative item-menu-left",
                  {
                    "font-medium": menuActive === data?.id,
                    "px-16px pt-16px pb-12px": true,
                    active: isActiveParent,
                  }
                )}
              >
                <div
                  className={classNames(
                    "absolute transform top-1/2 -translate-y-1/2 transition-all",
                    {
                      "right-3": !collapseMenu,
                      "right-0": collapseMenu,
                    }
                  )}
                >
                  <ChevronRight
                    className={classNames(
                      "text-20px transform transition-all",
                      {
                        "rotate-90": isExpanded,
                        "rotate-0": !isExpanded,
                      }
                    )}
                  />
                </div>

                <div
                  className={classNames(
                    "w-24px h-24px overflow-hidden hover:text-03A89C",
                    {
                      "text-03A89C": isActiveParent,
                    }
                  )}
                >
                  {data?.icon}
                </div>
                {!collapseMenu && (
                  <span
                    className={classNames(
                      "text-black opacity-85 hover:text-03A89C",
                      {
                        "text-03A89C": isActiveParent,
                      }
                    )}
                  >
                    {t(data?.title)}
                  </span>
                )}
              </div>
              {!collapseMenu && (
                <Collapse isOpened={isExpanded} className="">
                  {data?.children?.map((item, index) => {
                    return (
                      <div key={index} className="pl-3">
                        <MenuItem
                          data={item}
                          collapseMenu={collapseMenu}
                          className="border-l border-D9D9D9 border-opacity-60"
                          menuActive={menuActive}
                        />
                      </div>
                    );
                  })}
                </Collapse>
              )}
            </div>
          );
        case "item":
          if (data?.link === null && !collapseMenu) {
            return (
              <div
                className={classNames("menuItem", {
                  "px-16px pt-16px pb-12px": true,
                })}
                key={data?.id}
              >
                <span className="uppercase text-12px text-black opacity-85 font-medium">
                  {t(data?.title)}
                </span>
              </div>
            );
          } else {
            return (
              <div
                key={data?.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeItem(data?.link);
                }}
                className={classNames(
                  "menuItem flex items-center gap-12px cursor-pointer item-menu-left cyan",
                  {
                    "active font-medium": menuActive === data?.id,
                    "px-16px pt-16px pb-12px": true,
                  }
                )}
              >
                <div className="w-24px h-24px overflow-hidden">
                  {data?.icon}
                </div>
                {!collapseMenu && (
                  <span className="text-black opacity-85">
                    {t(data?.title)}
                  </span>
                )}
              </div>
            );
          }

        default:
          break;
      }
    },
    [
      onChangeItem,
      isShowCollapse,
      isActiveParent,
      isExpanded,
      collapseMenu,
      menuActive,
      t,
    ]
  );

  return (
    <div className={className}>
      {data.module ? (
        <CheckPermission
          module={{
            code: data.module.code,
            permissions: data.module.permissions,
          }}
        >
          {render(data)}
        </CheckPermission>
      ) : (
        render(data)
      )}
    </div>
  );
}

const Menu = ({ menu = [], collapseMenu }) => {
  const menuActive = useActiveMenu();
  const parentId = useMemo(() => {
    return getParentId(menuActive, menu);
  }, [menu, menuActive]);
  return (
    <div className="select-none">
      {menu.map((item, index) => {
        return (
          <MenuItem
            key={index}
            data={item}
            collapseMenu={collapseMenu}
            menuActive={menuActive}
            parentId={parentId}
          />
        );
      })}
    </div>
  );
};

export default Menu;
