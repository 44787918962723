import { useMemo } from "react";
import { useSelector } from "react-redux";
import { checkPermissionList } from "../helpers/permissions";

function usePermission(module = {}) {
  const userPermissions = useSelector(
    (state) => state.user?.infoUser?.permissions
  );

  return useMemo(() => {
    if (userPermissions) {
      return checkPermissionList(
        userPermissions,
        module.code,
        module.permissions
      );
    } else {
      return false;
    }
  }, [userPermissions, module.code, module.permissions]);
}

export default usePermission;
