import InjectAxiosInterceptors from "components/InjectAxiosInterceptors";
import PrivateRoute from "components/PrivateRoute";
import Page from "pages";
import { memo } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { AppRoutes } from "./app-constants/AppRoutes";
import ConfigLayout from "./layouts/ConfigLayout/ConfigLayout";
import LoginLayout from "./layouts/LoginLayout";
const exact = true;

const routes = [
  { path: AppRoutes.login, component: Page.Login, exact, layout: LoginLayout },
  {
    path: AppRoutes.forgot_password,
    component: Page.ForgotPassword,
    exact,
    layout: LoginLayout,
  },
  { path: AppRoutes.page403, component: Page.Page403, exact },
  { path: AppRoutes.page404, component: Page.Page404, exact },
  // vmg
  {
    path: AppRoutes.category,
    component: Page.CategoryPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.video_group,
    component: Page.VideoGroupPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.video_group_detail,
    component: Page.VideoGroupDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.video,
    component: Page.VideoPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.config_popup,
    component: Page.PopupPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.config_baner,
    component: Page.BannerPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artist,
    component: Page.ArtistPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artist_photo,
    component: Page.ArtistPhotoPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artist_post,
    component: Page.ArtistPostPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artist_reward,
    component: Page.ArtistRewardPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artist_detail(),
    component: Page.ArtistDetail,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.products,
    component: Page.ProductsPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.productCategory,
    component: Page.ProductCategoryPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.emojie,
    component: Page.EmojiePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.gifts,
    component: Page.GiftsPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.greeting_card_log,
    component: Page.GreetingCardLogPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.event_ticket,
    component: Page.EventTicketPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.event,
    component: Page.EventPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.event_artist,
    component: Page.EventArtistPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.order,
    component: Page.OrderPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.orderProduct,
    component: Page.OrderProductPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.order_detail(),
    component: Page.OrderDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.paymentType,
    component: Page.PaymentTypePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.shippingUnit,
    component: Page.ShippingUnitPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.artistsProductCategories,
    component: Page.ArtistsProductCategoryPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.configSystem,
    component: Page.ConfigSystemPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.user,
    component: Page.UserPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.accoutSystem,
    component: Page.AccountSystemPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.service,
    component: Page.ServicePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.premium_type,
    component: Page.PremiumTypePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.notification,
    component: Page.NotificationPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.configHome,
    component: Page.ConfigHomePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.topic,
    component: Page.TopicPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.tag,
    component: Page.TagPage,
    exact,
    layout: ConfigLayout,
  },
  // {
  //   path: AppRoutes.dashboard,
  //   component: Page.DashboardPage,
  //   exact,
  //   layout: ConfigLayout,
  // },
  {
    path: AppRoutes.statistics_artists,
    component: Page.StatisticsArtistsPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_artists_sale,
    component: Page.StatisticsArtistsSalePage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_access_video,
    component: Page.StatisticsAccessVideoPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_system,
    component: Page.StatisticsSystemPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_history_premium,
    component: Page.HistoryPremiumPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_premium_type_buy_report,
    component: Page.PremiumTypeBuyReportPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.statistics_premium_type_buy_report_detail,
    component: Page.PremiumTypeBuyReportDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.history_premium,
    component: Page.HistoryPremiumPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.album,
    component: Page.AlbumPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.album_video,
    component: Page.AlbumVideoPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.audio,
    component: Page.AudioPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.permission,
    component: Page.PermissionPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.permission_group,
    component: Page.PermissionGroup,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.permission_group_member,
    component: Page.PermissionGroupMember,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.charts,
    component: Page.ChartsPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.user_detail(),
    component: Page.UserDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.video_report,
    component: Page.VideoReportPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.audio_report,
    component: Page.AudioReportPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.publishers,
    component: Page.PublishersPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.video_report_detail,
    component: Page.VideoReportDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.audio_report_detail,
    component: Page.AudioReportDetailPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.audit_logs,
    component: Page.AuditLogPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.log_sms_history,
    component: Page.LogSMSPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.history_used_coin,
    component: Page.HistoryUsedCoinPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.present_artist,
    component: Page.PresentArtistPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.present_audio,
    component: Page.PresentAudioPage,
    exact,
    layout: ConfigLayout,
  },
  {
    path: AppRoutes.present_video,
    component: Page.PresentVideoPage,
    exact,
    layout: ConfigLayout,
  },
];

const ConfigLayoutProvider = ({ children }) => {
  const location = useLocation();
  if (location.pathname === "/login") {
    return <LoginLayout>{children}</LoginLayout>;
  } else {
    return <ConfigLayout>{children}</ConfigLayout>;
  }
};
const RouteWrapper = memo(() => {
  return (
    <BrowserRouter>
      <InjectAxiosInterceptors />
      <ConfigLayoutProvider>
        <Switch>
          {routes.map((route) => (
            <PrivateRoute key={route.path} {...route} />
          ))}
          <Route exact path="/">
            <Redirect to={AppRoutes.login} />
          </Route>
        </Switch>
      </ConfigLayoutProvider>
    </BrowserRouter>
  );
});

export default RouteWrapper;
