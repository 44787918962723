export const THEME = {
  cyan: 'cyan',
  darkRed: 'darkRed',
};

export const THEME_DATA = {
  darkRed: {
    name: THEME.darkRed,
    primaryColor: '#03A89C',
    errorColor: '#F5222D',
    warningColor: '#FA8C16',
    successColor: '#237804',
    infoColor: '#F5F5F5',
  },
  cyan: {
    name: THEME.cyan,
    primaryColor: '#03A89C',
    errorColor: '#ff4d4f',
    warningColor: '#faad14',
    successColor: '#52c41a',
    infoColor: '#1890ff',
  },
};
