import { Form } from "antd";
import classNames from "classnames";
import Button from "components/Button/Button";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import InputPassword from "components/Input/InputPassword";
import { useFormik } from "formik";
import { yupString } from "helpers/yup";
import useTranslateService from "hooks/useTranslateService";
import { useEffect } from "react";
import * as Yup from "yup";
import "./FormChangePassword.scss";

const FormChangePassword = ({
  onClose,
  setformAntd,
  theme,
  onChangePassword,
}) => {
  const { t } = useTranslateService();
  const [form] = Form.useForm();

  useEffect(() => {
    setformAntd && setformAntd(form);
  }, [form]);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      showCurrentPassword: true,
    },
    validationSchema: Yup.object({
      password: yupString({
        fieldCode: "new_password",
        isRequired: true,
        isPassword: true,
      }),

      currentPassword: yupString({
        fieldCode: "current_password",
        isRequired: true,
        //isPassword: true,
      }),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], t("passwordNotMatch"))
        .required(
          t("fieldNotEmpty", {
            field: t("enter_new_password"),
          })
        ),
    }),
    onSubmit: (data) => {
      onChangePassword({
        old_password: data?.currentPassword,
        password: data?.password,
        re_password: data?.confirmPassword,
      });
    },
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = formik;

  return (
    <div className="p-5">
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item
          className="text-14px mt-24px"
          label={t("current_password")}
          required
        >
          <InputPassword
            className={classNames("h-40px", theme)}
            name="currentPassword"
            onBlur={formik.handleBlur}
            error={touched?.currentPassword && errors["currentPassword"]}
            value={values.currentPassword}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("currentPassword", value);
            }}
            maxLength={20}
            autoComplete="new-password"
          />
          <ErrorMessage
            error={touched.currentPassword && errors.currentPassword}
          />
        </Form.Item>

        <Form.Item
          className="text-14px mt-24px"
          label={t("new_password")}
          required
        >
          <InputPassword
            className={classNames("h-40px", theme)}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            name="password"
            error={errors.password && touched.password && errors["password"]}
            value={values.password}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("password", value);
            }}
            maxLength={20}
            autoComplete="new-password"
          />
          <ErrorMessage
            error={errors.password && touched.password && errors["password"]}
          />
        </Form.Item>
        <Form.Item
          className="text-14px mt-24px"
          label={t("enter_new_password")}
          required
        >
          <InputPassword
            className={classNames("h-40px", theme)}
            name="confirmPassword"
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            error={
              errors.confirmPassword &&
              touched.confirmPassword &&
              errors["confirmPassword"]
            }
            value={values.confirmPassword}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("confirmPassword", value);
            }}
            maxLength={20}
            autoComplete="new-password"
          />
          <ErrorMessage
            error={
              errors.confirmPassword &&
              touched.confirmPassword &&
              errors["confirmPassword"]
            }
          />
        </Form.Item>
        <div className="flex mt-24px gap-8px justify-end">
          <Button
            type={"primary"}
            htmlType="submit"
            className="h-40px main cyan text-16px py-6px px-15px"
            name={t("btn.change")}
          />
          <Button
            className="h-40px secondary text-16px py-6px px-15px"
            name={t("btn.cancel")}
            onClick={onClose}
          />
        </div>
      </Form>
    </div>
  );
};

export default FormChangePassword;
