import { combineReducers } from 'redux';
import common from './common/reducer';
import user from './user/reducer';
import setting from './setting/reducer';

const reducer = combineReducers({
  user,
  common,
  setting,
});

export default reducer;
