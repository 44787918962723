import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuTop from "../../components/MenuTop/MenuTop";
import AUTH_ACTION from "../../redux/user/actions";
import MenuLeft from "./MenuLeft";
import MENU_LEFT_CONFIG from "./constants";

const ConfigLayout = ({ children }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user?.infoUser);
  useEffect(() => {
    if (!userInfo) {
      dispatch(AUTH_ACTION.getInfoUser());
    }
  }, [dispatch, userInfo]);

  return userInfo ? (
    <div className="config-layout">
      <MenuTop />
      <MenuLeft
        menus={MENU_LEFT_CONFIG}
        isShowTitle={true}
        classNameContent="overflow-hidden"
      >
        {children}
      </MenuLeft>
    </div>
  ) : null;
};

ConfigLayout.propTypes = {
  children: PropTypes.node,
};

export default ConfigLayout;
