export const actionTypes = {
  //user
  LOGIN: 'user/LOGIN',
  SET_INFO_LOGIN: 'user/SET_INFO_LOGIN',
  SET_INFO_USER: 'user/SET_INFO_USER',
  SET_LOADING_USER: 'user/SET_LOADING_USER',

  //theme
  SET_THEME: 'SET_THEME',

  // menu
  SET_MENU: 'SET_MENU',

  //common
  LOADING: 'LOADING',
};
