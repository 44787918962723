export const MODULES = {
  ACCOUNT: "account",
  NOTIFICATION: "notification",
  CATEGORY: "category",
  BANNER_MANAGEMENT: "banner_management",
  POPUP_MANAGEMENT: "popup_management",
  ARTIST: "artist",
  IMAGE_ARTIST: "image_artist",
  POST_ARTIST: "post_artist",
  ORDER_MANAGEMENT: "order_management",
  MANAGE_PRODUCT_CATEGORIES: "manage_product_categories",
  PRODUCT_MANAGEMENT: "product_management",
  MANAGE_ARTIST_PRODUCT_CATEGORIES: "manage_artist_product_categories",
  MANAGE_ALBUMS: "manage_albums",
  MANAGE_ALBUM_VIDEO: "manage_album_video",
  AUDIO_MANAGEMENT: "audio_management",
  MANAGE_VIDEO_GROUPS: "manage_video_groups",
  VIDEO_MANAGEMENT: "video_management",
  MANAGING_ARTIST_AWARDS: "managing_artist_awards",
  EMOJI_MANAGEMENT: "emoji_management",
  GIFT_MANAGEMENT: "gift_management",
  MANAGING_CARDS: "managing_cards",
  MANAGE_PAYMENT_METHODS: "manage_payment_methods",
  MANAGE_SHIPPING_UNITS: "manage_shipping_units",
  MANAGE_PUBLISHER: "manage_publisher",
  SERVICE_MANAGEMENT: "service_management",
  MANAGE_SERVICE_PACKAGES: "manage_service_packages",
  PACKAGE_PURCHASE_HISTORY: "package_purchase_history",
  SYSTEM_CONFIGURATION: "system_configuration",
  CONFIGURE_THE_HOME_SCREEN: "configure_the_home_screen",
  ADMIN_MANAGEMENT: "admin_management",
  ARTIST_STATISTICS: "artist_statistics",
  SYSTEM_STATISTICS: "system_statistics",
  MANAGE_TOPICS_CUSTOMER_GROUPS: "manage_topics_customer_groups",
  MANAGE_PERMISSION_GROUPS: "manage_permission_groups",
  DECENTRALIZATION_OF_PROFESSIONAL_AUTHORITY:
    "decentralization_of_professional_authority",
  MEMBERSHIP_AUTHORIZATION: "membership_authorization",
  GREETING_CARD_LOG: "greeting_card_log",
  MANAGE_EVENT: "manage_event",
  MANAGE_EVENT_ARTIST: "manage_event_artist",
  MANAGE_EVENT_TICKET: "manage_event_ticket",
  MANAGE_TAGS: "manage_tags",
  VIDEO_STATISTICS: "video_statistics",
  AUDIO_STATISTICS: "audio_statistics",
  PAYMENT_STATISTICS: "payment_statistic",
  AUDIT_LOGS_HISTORY: "audit_logs_history",
  LOG_SMS_HISTORY: "log_sms_history",
  ARTIST_GIFT_LOG: "artistgifts_log",
  AUDIO_GIFT_LOG: "audiogifts_log",
  VIDEO_GIFT_LOG: "videogifts_log",
};
export const ROLES = {
  SHOW: "show",
  PENDING: "pending",
  LIST: "list",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  APPROVE: "approve",
  SEND_CARDS: "send_cards",
  ASSIGN_ROLE: "assign_role",
  REVENUE_STATISTICS: "revenue_statistics",
  VIDEO_ACCESS_STATISTICS: "video_access_statistics",
  STATISTICS_ON_SERVICE_PACKAGE_PURCHASES:
    "statistics_on_service_package_purchases",
  PACKAGE_PURCHASE_STATISTICS: "package_purchase_statistics",

  VIDEO_STATISTICS: "video_statistics",
  VIDEO_REPORT_VIEW: "video_report_view",
  VIDEO_REPORT_LIKE: "video_report_like",
  VIDEO_REPORT_COMMENT: "video_report_comment",
  VIDEO_REPORT_GIFT: "video_report_gift",
  VIDEO_REPORT_SHARE: "video_report_share",
  VIDEO_REPORT_DURATION: "video_report_duration",

  AUDIO_STATISTICS: "audio_statistics",
  AUDIO_REPORT_LISTEN: "audio_report_listen",
  AUDIO_REPORT_LIKE: "audio_report_like",
  AUDIO_REPORT_COMMENT: "audio_report_comment",
  AUDIO_REPORT_GIFT: "audio_report_gift",
  AUDIO_REPORT_SHARE: "audio_report_share",
  AUDIO_REPORT_DURATION: "audio_report_duration",

  PAYMENT_STATISTICS: "payment_statistic",
  AUDIT_LOGS_HISTORY: "audit_logs_history",
  LOG_SMS_HISTORY: "log_sms_history",
};
