export const AppRoutes = {
  //auth
  login: "/login",
  forgot_password: "/forgot-password",
  change_password: "/change-password",
  page403: "/403",
  page404: "/404",

  // vmg
  category: "/category",
  video: "/video",
  video_group: "/video-group",
  video_group_detail: "/video-group/:id",
  config_baner: "/config-banner",
  config_popup: "/config-popup",
  artist: "/artist",
  present_artist: "/present-artist",
  present_audio: "/present-audio",
  present_video: "/present-video",
  artist_present_report: "/artist-present_report",
  artist_photo: "/artist-photo",
  artist_post: "/artist-post",
  artist_reward: "/artist-reward",
  artist_detail: (id) => (id ? `/artist-detail/${id}` : "/artist-detail/:id"),
  products: "/products",
  productCategory: "/product-category",
  emojie: "/emojie",
  gifts: "/gifts",
  greeting_card_log: "/greeting-card-log",
  event_ticket: "/event-ticket",
  event_artist: "/event-artist",
  event: "/event",
  order: "/order",
  order_detail: (id) => (id ? `/order-detail/${id}` : "/order-detail/:id"),
  orderProduct: "/order-product",
  paymentType: "/payment-type",
  shippingUnit: "/shipping-unit",
  artistsProductCategories: "/artists-product-categories",
  configSystem: "/config-system",
  configHome: "/config-home",
  user: "/account-user",
  accoutSystem: "/account-system",
  service: "/service",
  premium_type: "/premium-type",
  notification: "/notification",
  topic: "/topic",
  tag: "/tag",
  statistics_system: "/statistics-system",
  statistics_artists: "/statistics-artists",
  video_report: "/video-report",
  audio_report: "/audio-report",
  statistics_artists_sale: "/statistics-artists-sale",
  statistics_access_video: "/statistics-access-video",
  statistics_history_premium: "/statistics-history-premium",
  statistics_premium_type_buy_report: "/statistics-premium-type-buy-report",
  history_used_coin: "/history-used-coin",
  history_premium: "/history-premium",
  audio: "/audio",
  album: "/album",
  album_video: "/album-video",
  user_detail: (id) => (id ? `/user-detail/${id}` : "/user-detail/:id"),
  publishers: "/publishers",
  // video_report_detail: (id) => (id ? `/video-report-detail/${id}` : "/video-report-detail/:id"),
  video_report_detail: "/video-report-detail",
  audio_report_detail: "/audio-report-detail",
  // audio_report_detail: (id) =>
  //   id ? `/audio-report-detail/${id}` : "/audio-report-detail/:id",
  statistics_premium_type_buy_report_detail:
    "/statistics-premium-type-buy-report-detail",

  //config
  config_account: "/config/account",
  config_account_edit: "/config/account/edit",
  config_secure: "/config/secure",
  config_lang: "/config/lang",
  config_sms: "/config/sms",
  config_sms_setting: "/config/sms/setting",
  config_mail: "/config/mail",
  config_mail_setting: "/config/mail/setting",
  config_mail_send: "/config/mail/send",
  config_resident_vip: "/config/resident-vip",
  config_resident_vip_edit: "/config/resident-vip/edit",
  config_real_estate: "/config/real-estate",

  //department
  department: "/department",
  department_detail: "/department/:id",

  //employee
  listEmployee: "/employees",
  employee_detail: "/employees/:id",
  adddEmployee: "/employee/add",
  editEmployee: "/employee/edit",
  uploadEmployee: "/employee/uploadEmployee",

  //project
  project: "/projects",
  permission: "/permission",
  permission_group: "/permission-group",
  audit_logs: "/audit_logs",
  log_sms_history: "/log-sms-history",
  permission_group_member: "/permission-group-member",
  charts: "/charts",

  //project department
  projectDepartment: (idProject) =>
    `/project/${idProject ?? ":idProject"}/department`,
  projectDepartmentDetail: (idProject, id) =>
    `/project/${idProject ?? ":idProject"}/department/${id ?? ":id"}`,

  //project Employee
  configProfile: (idProject) =>
    `/project/${idProject ?? ":idProject"}/config/profile`,
  configProfileEdit: (idProject) =>
    `/project/${idProject ?? ":idProject"}/config/profile/edit`,
  configProfileSecure: (idProject) =>
    `/project/${idProject ?? ":idProject"}/config/secure`,
  configProfileLang: (idProject) =>
    `/project/${idProject ?? ":idProject"}/config/lang`,
  //app
  home: "/home",
};
