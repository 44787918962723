export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_UPLOAD_URL = process.env.REACT_APP_BASE_UPLOAD_URL;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const BASE_URL_BIZFLY = process.env.REACT_APP_VODBIZFLY_BASE_URL;
export const STATUS_API = {
  success: "200",
  401: "401",
};
export const REGEX_ONLY_SPACE_AFTER_THE_WORD = /^(\w+\s?)*\s*$/;
export const REGEX_TEXT_EXCEPT_SPECIAL_CHARACTERS =
  /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const MIN_VALUE_INPUT_NUMBER = 0;
export const MAX_LENGTH_INPUT_NUMBER = 9;
export const MAX_LENGTH_TEN_NUMBER = 10;
export const MAX_LENGTH_TEXT_INPUT = 250;
export const MAX_LENGTH_TEXT_NOTE = 1000;
export const MAX_LENGTH_LINK_SOCIAL = 499;
export const MAX_LENGTH_TEXT_100 = 100;
export const MAX_LENGTH_TEXT_50 = 50;

export const TYPE_SEARCH = {
  input: "input",
  select: "select",
  asyncSelect: "asyncSelect",
  date: "date",
  dateYear: "dateYear",
  dateRange: "dateRange",
};
