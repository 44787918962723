import { getSecretKey } from 'helpers/storage';

function useAuthenticated() {
  // const isToken = getToken();
  // return !!isToken;
  const isSecretKey = getSecretKey();
  return !!isSecretKey;
}

export default useAuthenticated;
