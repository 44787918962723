import usePermission from "hooks/usePermission";

const NotFound = () => {
  return (
    <div className="w-full h-full flex-col flex-center">
      <p className="text-6xl font-bold">403</p>
      <p className="text-[20px]">Bạn không có quyền truy cập trang này</p>
    </div>
  );
};

function CheckPermission({ module, children, showNotFound }) {
  const isAccess = usePermission(module);
  if (!isAccess) {
    if (showNotFound) {
      return <NotFound />;
    }
    return null;
  }
  return children;
}

export default CheckPermission;
