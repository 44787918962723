import PropTypes from 'prop-types';
import classNames from 'classnames';

function ErrorMessage({ className, error }) {
  return <>{error ? <div className={classNames('leading-22px text-FF4D4F', className)}>{error}</div> : null}</>;
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.any,
  field: PropTypes.string,
};

export default ErrorMessage;
