import { LANG_CODE } from 'app-constants/index';
import classNames from 'classnames';
import { useClickOutside } from 'hooks/useClickOutside';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import flagVN from '../../resources/icons/flag-vn.png';
import flagEN from '../../resources/icons/flag-en.png';
import arrDown from '../../resources/icons/icon-arrow-down-white.svg';

const listLang = [
  {
    name: 'EN',
    icon: flagEN,
    code: 'en',
  },
  {
    name: 'VI',
    icon: flagVN,
    code: 'vi',
  },
];

function ChangeLocales() {
  const [openLang, setOpenLang] = useState(false);
  const langRef = useRef();
  const { i18n } = useTranslation();

  useClickOutside(langRef, () => {
    setOpenLang(false);
  });

  const handleChangeLang = (lang) => {
    if (i18n.language !== lang) {
      if (Object.keys(LANG_CODE).includes(lang)) {
        localStorage.setItem('lang', lang);
        window.location.reload();
      }
    }
    setOpenLang(false);
  };

  return (
    <div
      ref={langRef}
      className="rounded-4px bg-5ACCBD border-5F1D02 px-12px py-8px cursor-pointer w-90px relative flex-shrink-0"
    >
      <div className="flex justify-between items-center" onClick={() => setOpenLang(!openLang)}>
        <img src={i18n.language === LANG_CODE.vi ? flagVN : flagEN} alt="" />
        <p className="text-white text-14px mx-8px uppercase">{i18n.language?.toLocaleUpperCase()}</p>
        <img src={arrDown} alt="" />
      </div>

      {openLang && (
        <div className="absolute w-full z-20 right-0 top-topMenuUser shadow-menuUser  bg-gray-300  border border-5ACCBD rounded-4px">
          {listLang.map((lang) => (
            <div
              key={lang.code}
              className={classNames(
                'px-12px py-8px cursor-pointer flex items-center hover:bg-5ACCBD hover:bg-opacity-50 transition-all',
                {
                  'bg-5ACCBD': lang.code === i18n.language,
                },
              )}
              onClick={() => handleChangeLang(lang.code)}
            >
              <img src={lang.icon} alt="" />
              <p className="text-white text-14px mx-8px uppercase">{lang.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ChangeLocales;
