import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App";
import { ConfigProvider } from "antd";
import "./i18n";
import "./styles/index.scss";
import i18n from "./i18n";
import english from "antd/es/locale/en_US";
import vietnamese from "antd/es/locale/vi_VN";

// import ErrorBoundary from './components/ErrorBoundary';

import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
const locales = {
  en: english,
  vi: vietnamese,
};

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

ReactDOM.render(
  // <ErrorBoundary>
  <ConfigProvider
    locale={i18n.language === "vi" ? locales["vi"] : locales["en"]}
  >
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </ConfigProvider>,
  // </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
