export const getUserName = () => {
  return localStorage.getItem("userName");
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getSecretKey = () => {
  return localStorage.getItem("secretKey");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getIsRemember = () => {
  return localStorage.getItem("remember");
};

export const getFilterStorage = () => {
  const itemStr = localStorage.getItem("filter");
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiration) {
    localStorage.removeItem("filter");
    return null;
  }
  return item;
};
export const getFilterStorageByKey = (_key) => {
  const filter = getFilterStorage();
  return filter?.[_key];
};
export const setFilterStorage = (_data = {}, expirationInMinutes) => {
  const filter = getFilterStorage() || {};
  const now = new Date();
  localStorage.setItem(
    "filter",
    JSON.stringify({
      ...filter,
      ..._data,
      expiration: now.getTime() + expirationInMinutes * 60000,
    })
  );
};
