import { actionTypes } from '../actionTypes';

const initialState = {
  loading: false,
  infoLogin: null,
  infoUser: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_INFO_LOGIN:
      return { ...state, loading: false, infoLogin: action.payload };
    case actionTypes.SET_INFO_USER:
      return { ...state, loading: false, infoUser: action.payload };
    case actionTypes.SET_LOADING_USER:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
