import { AppRoutes } from "app-constants/AppRoutes";
import useAuthenticated from "hooks/useAuthenticated";
import useQueryString from "hooks/useQuerystring";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({
  layout: Layout,
  component: Component,
  isAuthRoute,
  isPrivate,
  path,
  ...props
}) => {
  const isAuthenticated = useAuthenticated();
  const { pathname } = useLocation();
  const { redirect } = useQueryString();
  if (!isAuthenticated && isAuthRoute) {
    return (
      <Redirect
        key={path}
        push
        exact={true}
        to={AppRoutes.login + "?redirect=" + pathname}
      />
    );
  }

  if (
    isAuthenticated &&
    [AppRoutes.login, AppRoutes.forgot_password].includes(pathname)
  ) {
    return (
      <Redirect
        push
        key={path?.toString()}
        exact={true}
        to={redirect ?? AppRoutes.category}
      />
    );
  }

  return (
    <Route
      {...props}
      path={path}
      render={(ownProps) => {
        return <Component {...ownProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  isAuthRoute: PropTypes.bool,
  isPrivate: PropTypes.bool,
  path: PropTypes.string,
};

export default PrivateRoute;
