import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'app-constants/AppRoutes';
import { useSelector } from 'react-redux';
import { iconNoNotify } from 'resources/icons';
import { getNotifications } from 'services/firebase';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'antd';

const Notifications = ({ onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const setting = useSelector((state) => state.user.setting);

  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [isLast, setIsLast] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const locale = useMemo(
    () => ({
      emptyText: (
        <div className="p-16px w-full h-full flex-center flex-col">
          <img src={iconNoNotify} alt="" />
          <div className="mt-35px text-BFBFBF font-bold text-14px leading-22px">{t('no_notification')}</div>
        </div>
      ),
    }),
    [t],
  );

  useEffect(() => {
    const unsubscribe = getNotifications(
      setting?.userId,
      lastDoc,
      isLast,
      isNext,
      (querySnapshot) => {
        const newNotifications = [...data];
        querySnapshot.docChanges()?.forEach((change) => {
          const findIndex = newNotifications.findIndex((item) => item.docId === change?.doc?.id);
          if (change.type === 'added') {
            if (findIndex !== -1) {
              newNotifications.splice(findIndex, 1, {
                docId: change?.doc?.id,
                ...change?.doc?.data(),
              });
            } else {
              newNotifications.push({
                docId: change?.doc?.id,
                ...change?.doc?.data(),
              });
            }
          }
        });
        setData([...newNotifications.sort((a, b) => b.created_at - a.created_at)]);
        const length = newNotifications?.length;
        setIsNext(false);
        if (length > 0) {
          setLastDoc(newNotifications[length - 1]?.created_at);
        } else {
          setIsLast(true);
        }
      },
      (error) => console.log(error),
    );
    return unsubscribe;
  }, [lastDoc, setting?.userId, isLast, isNext, data]);

  const onNext = () => {
    if (!isNext && data.length > 9) {
      setIsNext(true);
    }
  };

  const onAction = (item) => {
    !!onClose && onClose();
    switch (item?.type) {
      case 1:
        return history.push(AppRoutes.config_secure, { openChangePassword: true });
      case 3:
      case 4:
      case 5:
      case 6:
        return item?.siteId && history.push(`${AppRoutes.projectResident(item?.siteId)}?tab=recommend`);
      default:
        break;
    }
  };

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 443,
        overflow: 'auto',
      }}
    >
      <InfiniteScroll dataLength={data.length} next={onNext} hasMore={!isLast} scrollableTarget="scrollableDiv">
        <List
          dataSource={data}
          renderItem={(item) => {
            const btn_text = item?.type === 1 ? 'btn.change_password' : item?.type === 2 ? '' : 'btn.see_detail';
            return (
              <div key={item?.docId} className="pt-16px pl-16px pr-23px pb-20px flex flex-col notification-item">
                <div className={`font-semibold text-16px leading-20px text-000E44`}>{item?.content}</div>
                <div className="flex flex-row justify-between items-end mt-8px">
                  <div className={`text-14px leading-16px font-normal text-000E44`}>
                    {moment(item.created_at).format('HH:mm [,] DD/MM/YYYY')}
                  </div>
                  <Button
                    name={t(btn_text)}
                    onClick={() => onAction(item)}
                    type={'primary'}
                    className="cyan h-32px px-12px py-8px text-12px leading-16px"
                  />
                </div>
              </div>
            );
          }}
          locale={locale}
        />
      </InfiniteScroll>
    </div>
  );
};

Notifications.propTypes = {
  onClose: PropTypes.func,
};

export default Notifications;
