import { scrollTo } from "@dudojs/browser-utils";
import { LABEL_COLOR, TYPE_VIDEO } from "app-constants";
import avataImg from "resources/images/avatar.png";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0 || !bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const download = (fileUrl, fileName) => {
  if (fileUrl) {
    const a = document.createElement("a");
    a.href = fileUrl;
    a.setAttribute("download", fileName);
    a.click();
    a.remove();
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const lowerCaseFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const isEmptyField = (field) => {
  return [null, undefined].includes(field);
};

const toLowerCaseNonAccentVietnamese = (str) => {
  let stringConvert = str;
  stringConvert = stringConvert.toLowerCase();

  stringConvert = stringConvert.replace(
    /à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,
    "a"
  );
  stringConvert = stringConvert.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  stringConvert = stringConvert.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  stringConvert = stringConvert.replace(
    /ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,
    "o"
  );
  stringConvert = stringConvert.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  stringConvert = stringConvert.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  stringConvert = stringConvert.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  stringConvert = stringConvert.replace(
    /\u0300|\u0301|\u0303|\u0309|\u0323/g,
    ""
  ); // Huyền sắc hỏi ngã nặng
  stringConvert = stringConvert.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return stringConvert;
};

// This function keeps the casing unchanged for str, then perform the conversion
function toNonAccentVietnamese(str) {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}

const formatTotal = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getColorLabel = (color) => {
  return LABEL_COLOR.find((item) => color === item.value);
};

export const scrollToElement = (element, offset = -60) =>
  scrollTo(element, offset);

export const getAvatar = (avatar) => {
  if (!avatar) return avataImg;
  return avatar;
};

const getValueSearch = (val, isTrim) => {
  if ([null, undefined].includes(val)) return undefined;
  if (isTrim) return val?.trim();
  return val;
};
const removeEmptyField = (obj) => {
  for (const objKey in obj) {
    if ([null, ""].includes(obj[objKey])) {
      delete obj[objKey];
    }
  }
  return obj;
};
export const checkResponse = (res) => {
  if (res?.errors) {
    return false;
  }
  return true;
};

export const checkUrlVideoGetThumb = (url) => {
  const bizfly = /bfcmedia-wavebox/;
  const youtube = /youtube/;

  if (bizfly.test(url)) {
    return TYPE_VIDEO.BIZFLY;
  }

  if (youtube.test(url)) {
    return TYPE_VIDEO.YOUTUBE;
  }
};

export const getIDfromURL = (url) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  console.log("The supplied URL is not a valid youtube URL");

  return "";
};

export const formatMoney = (dataNumber, n, x) => {
  try {
    if (!dataNumber) {
      return 0;
    }
    const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return Number(dataNumber)
      .toFixed(Math.max(0, ~~n))
      .replace(new RegExp(re, "g"), "$&.");
  } catch (error) {
    return 0;
  }
};

const Utils = {
  convertBase64,
  formatBytes,
  download,
  capitalizeFirstLetter,
  isEmptyField,
  toLowerCaseNonAccentVietnamese,
  formatTotal,
  getColorLabel,
  getAvatar,
  lowerCaseFirstLetter,
  toNonAccentVietnamese,
  getValueSearch,
  removeEmptyField,
  checkResponse,
  checkUrlVideoGetThumb,
  getIDfromURL,
  formatMoney,
};

export default Utils;
