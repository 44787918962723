export const getParentId = (id, tree) => {
  let parentId;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.id === id)) {
        parentId = node.id;
      } else if (getParentId(id, node.children)) {
        parentId = getParentId(id, node.children);
      }
    }
  }
  return parentId;
};
