import ChangeLocales from 'components/ChangeLocales/ChangeLocales';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import logo_vmg from 'resources/images/logo_vmg.png';

const LoginLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-D9D9D9 flex-center w-full h-full relative overflow-hidden">
      <div className="absolute right-32px top-32px">{/* <ChangeLocales /> */}</div>
      <div className="relative">
        <div className="w-137px h-80px absolute left-1/2 transform -translate-x-1/2 bottom-full mb-40px text-white flex-center">
          <img src={logo_vmg} alt="logo" />
        </div>
        {children}
      </div>
      <div className="transform text-F4E0E3 text-14px fixed bottom-27px left-1/2 -translate-x-1/2">
        {t('login.copyright')}
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
