import { Input } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function InputPassword({ placeholder, className, size, value, error, theme, ...props }) {
  return (
    <Input.Password
      {...props}
      className={classNames(
        'custom-input-password',
        {
          'input-password-err': error,
        },
        className,
        theme,
      )}
      value={value}
      placeholder={placeholder}
      size={size || 'small'}
    />
  );
}

InputPassword.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
};

export default InputPassword;
