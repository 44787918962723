import ProgressLoading from 'components/ProgressLoading/ProgressLoading';
import 'font-awesome/css/font-awesome.min.css';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import RouteWrapper from './RouteWrapper';
import './styles/App.scss';


function App() {
  const { loading } = useSelector((state) => state.common);
  return (
    <Suspense fallback={<ProgressLoading />}>
      {loading && <ProgressLoading />}
      <RouteWrapper />
    </Suspense>
  );
}

export default App;
