import { lazy, Suspense } from "react";

import ProgressLoading from "components/ProgressLoading/ProgressLoading";

const LazyLayout = (importStatement) => {
  const Component = lazy(importStatement);

  return (
    <Suspense fallback={<ProgressLoading />}>
      <Component />
    </Suspense>
  );
};

const Pages = {
  Login: lazy(() => import("./auth/Login")),
  ForgotPassword: lazy(() => import("./auth/ForgotPassword")),
  Page403: lazy(() => import("./auth/Page403")),
  Page404: lazy(() => import("./auth/Page404")),

  // vmg
  CategoryPage: () => LazyLayout(() => import("./category/CategoryPage")),
  VideoGroupPage: () => LazyLayout(() => import("./videoGroup/VideoGroupPage")),
  VideoGroupDetailPage: () =>
    LazyLayout(() => import("./videoGroup/Detail/VideoGroupDetailPage")),
  VideoPage: () => LazyLayout(() => import("./video/VideoPage")),
  BannerPage: () => LazyLayout(() => import("./banner/BannerPage")),
  PopupPage: () => LazyLayout(() => import("./popup/PopupPage")),
  ArtistPage: () => LazyLayout(() => import("./artists/ArtistsPage")),
  ArtistPhotoPage: () =>
    LazyLayout(() => import("./artistPhoto/ArtistPhotoPage")),
  ArtistPostPage: () => LazyLayout(() => import("./artistPost/ArtistPostPage")),
  ArtistRewardPage: () =>
    LazyLayout(() => import("./artistReward/ArtistRewardPage")),
  ArtistDetail: () => LazyLayout(() => import("./artists/ArtistDetail")),
  ProductsPage: () => LazyLayout(() => import("./products/ProductsPage")),
  ProductCategoryPage: () =>
    LazyLayout(() => import("./productCategory/ProductCategoryPage")),
  EmojiePage: () => LazyLayout(() => import("./emojie/EmojiePage")),
  GiftsPage: () => LazyLayout(() => import("./gifts/GiftsPage")),
  EventTicketPage: () =>
    LazyLayout(() => import("./eventTicket/EventTicketPage")),
  EventPage: () => LazyLayout(() => import("./event/EventPage")),
  EventArtistPage: () =>
    LazyLayout(() => import("./eventArtist/EventArtistPage")),
  GreetingCardLogPage: () =>
    LazyLayout(() => import("./greetingCardLog/GreetingCardLogPage")),
  OrderPage: () => LazyLayout(() => import("./order/OrderPage")),
  OrderDetailPage: () => LazyLayout(() => import("./order/OrderDetailPage")),
  OrderProductPage: () =>
    LazyLayout(() => import("./orderProduct/OrderProductPage")),
  PaymentTypePage: () =>
    LazyLayout(() => import("./paymentType/PaymentTypePage")),
  ShippingUnitPage: () =>
    LazyLayout(() => import("./shippingUnit/ShippingUnitPage")),
  ArtistsProductCategoryPage: () =>
    LazyLayout(() =>
      import("./artistsProductCategory/ArtistsProductCategoryPage")
    ),
  ConfigSystemPage: () =>
    LazyLayout(() => import("./configSystem/ConfigSystemPage")),
  UserPage: () => LazyLayout(() => import("./user/UserPage")),
  AccountSystemPage: () => LazyLayout(() => import("./accoutSystem")),
  ServicePage: () => LazyLayout(() => import("./services/ServicePage")),
  PremiumTypePage: () =>
    LazyLayout(() => import("./premiumType/PremiumTypePage")),
  NotificationPage: () =>
    LazyLayout(() => import("./notification/NotificationPage")),
  DashboardPage: () => LazyLayout(() => import("./dashboard/DashboardPage")),
  VideoReportPage: () =>
    LazyLayout(() => import("./statistic/VideoReport/VideoReportPage")),
  AudioReportPage: () =>
    LazyLayout(() => import("./statistic/AudioReport/AudioReportPage")),
  VideoReportDetailPage: () =>
    LazyLayout(() => import("./statistic/VideoReport/VideoReportDetailPage")),
  AudioReportDetailPage: () =>
    LazyLayout(() => import("./statistic/AudioReport/AudioReportDetailPage")),
  StatisticsArtistsPage: () =>
    LazyLayout(() =>
      import("./statistic/statisticsArtists/StatisticsArtistsPage")
    ),
  StatisticsSystemPage: () =>
    LazyLayout(() =>
      import("./statistic/statisticSystem/StatisticsSystemPage")
    ),
  StatisticsArtistsSalePage: () =>
    LazyLayout(() =>
      import(
        "./statistic/statisticsArtists/StatisticsArtistsSalePage/StatisticsArtistsSalePage"
      )
    ),
  StatisticsAccessVideoPage: () =>
    LazyLayout(() =>
      import(
        "./statistic/statisticsArtists/StatisticsAccessVideoPage/StatisticsAccessVideoPage"
      )
    ),
  HistoryPremiumPage: () =>
    LazyLayout(() =>
      import("./statistic/statisticSystem/historypremium/HistoryPremiumPage")
    ),
  PremiumTypeBuyReportPage: () =>
    LazyLayout(() =>
      import(
        "./statistic/statisticSystem/premiumTypeBuyReports/PremiumTypeBuyReportPage"
      )
    ),
  PremiumTypeBuyReportDetailPage: () =>
    LazyLayout(() =>
      import(
        "./statistic/statisticSystem/premiumTypeBuyReports/PremiumTypeBuyReportDetailPage"
      )
    ),
  AlbumPage: () => LazyLayout(() => import("./album/AlbumPage")),
  AlbumVideoPage: () =>
    LazyLayout(() => import("./albumsVideo/AlbumVideoPage")),
  AudioPage: () => LazyLayout(() => import("./audio/AudioPage")),
  PermissionPage: () => LazyLayout(() => import("./permission")),
  PermissionGroup: () => LazyLayout(() => import("./PermissionGroup")),
  PermissionGroupMember: () =>
    LazyLayout(() => import("./PermissionGroupMember/index")),
  ChartsPage: () => LazyLayout(() => import("./charts")),
  ConfigHomePage: () => LazyLayout(() => import("./configHome/ConfigHomePage")),
  TopicPage: () => LazyLayout(() => import("./topic/TopicPage")),
  TagPage: () => LazyLayout(() => import("./tag/TagPage")),
  UserDetailPage: () => LazyLayout(() => import("./user/UserDetail")),
  PublishersPage: () => LazyLayout(() => import("./publishers/PublishersPage")),
  AuditLogPage: () => LazyLayout(() => import("./auditLog/AuditLogPage")),
  LogSMSPage: () => LazyLayout(() => import("./logSMS/LogSMSPage")),
  HistoryUsedCoinPage: () =>
    LazyLayout(() => import("./historyUsedCoin/HistoryUsedCoinPage")),
  PresentArtistPage: () =>
    LazyLayout(() => import("./present/PresentArtistPage")),
  PresentAudioPage: () =>
    LazyLayout(() => import("./present/PresentAudioPage")),
  PresentVideoPage: () =>
    LazyLayout(() => import("./present/PresentVideoPage")),
};

export default Pages;
