import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';

function useTranslateService() {
  const { t, i18n } = useTranslation();

  const formatValueText = (text, color) => {
    if (text) {
      return reactStringReplace(text, /(##.*##)/g, (match, i) => (
        <span key={i} className={classNames('font-medium', color)}>
          {match.replaceAll('##', '')}
        </span>
      ));
    }
  };

  return {
    formatValueText,
    t,
    lang: i18n.language,
  };
}

export default useTranslateService;
