import AddReactionIcon from "@mui/icons-material/AddReaction";
import AlbumIcon from "@mui/icons-material/Album";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CategoryIcon from "@mui/icons-material/Category";
import ClassIcon from "@mui/icons-material/Class";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FilterIcon from "@mui/icons-material/Filter";
import InventoryIcon from "@mui/icons-material/Inventory";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LayersIcon from "@mui/icons-material/Layers";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum";
import RedeemIcon from "@mui/icons-material/Redeem";
import SettingsIcon from "@mui/icons-material/Settings";
import ShieldIcon from "@mui/icons-material/Shield";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TagIcon from "@mui/icons-material/Tag";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import { AppRoutes } from "app-constants/AppRoutes";
import { MENU_IDS } from "app-constants/menu";
import { MODULES, ROLES } from "app-constants/permissions";
import HistoryIcon from "@mui/icons-material/History";

const MENU_LEFT_CONFIG = [
  {
    id: MENU_IDS.menuUser,
    title: "Tài khoản người dùng",
    link: AppRoutes.user,
    icon: <PersonOutlineIcon />,
    type: "item",
    module: {
      code: MODULES.ACCOUNT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuNotification,
    title: "Cài đặt thông báo",
    link: AppRoutes.notification,
    icon: <NotificationsNoneIcon />,
    type: "collapse",
    children: [
      {
        id: MENU_IDS.menuNotification,
        title: "Thông báo",
        link: AppRoutes.notification,
        icon: <NotificationsNoneIcon />,
        type: "item",
        module: {
          code: MODULES.NOTIFICATION,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuTopic,
        title: "Topic",
        link: AppRoutes.topic,
        icon: <LayersIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_TOPICS_CUSTOMER_GROUPS,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuCategory,
    title: "Danh mục",
    link: AppRoutes.category,
    icon: <ContentCopyIcon />,
    type: "item",
    module: {
      code: MODULES.CATEGORY,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuTag,
    title: "Tags",
    link: AppRoutes.tag,
    icon: <TagIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_TAGS,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuBanner,
    title: "Banner",
    link: AppRoutes.config_baner,
    icon: <FilterIcon />,
    type: "item",
    module: {
      code: MODULES.BANNER_MANAGEMENT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuPopup,
    title: "Popup",
    link: AppRoutes.config_popup,
    icon: <LayersIcon />,
    type: "item",
    module: {
      code: MODULES.POPUP_MANAGEMENT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuVideo,
    title: "Quản lý video ",
    link: AppRoutes.video,
    icon: <VideoLibraryIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuVideo,
        title: "Video",
        link: AppRoutes.video,
        icon: <VideoLibraryOutlinedIcon />,
        type: "item",
        module: {
          code: MODULES.VIDEO_MANAGEMENT,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuVideoGroup,
        title: "Nhóm video",
        link: AppRoutes.video_group,
        icon: <ClassIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_VIDEO_GROUPS,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuArtist,
    title: "Quản lý nghệ sĩ",
    link: AppRoutes.artist,
    icon: <PersonIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuArtist,
        title: "Nghệ sĩ",
        link: AppRoutes.artist,
        icon: <PersonIcon />,
        type: "item",
        module: {
          code: MODULES.ARTIST,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuArtistPost,
        title: "Bài viết nghệ sĩ",
        link: AppRoutes.artist_post,
        icon: <MenuBookIcon />,
        type: "item",
        module: {
          code: MODULES.POST_ARTIST,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuArtistPhoto,
        title: "Ảnh nghệ sĩ",
        link: AppRoutes.artist_photo,
        icon: <PhotoAlbumIcon />,
        type: "item",
        module: {
          code: MODULES.IMAGE_ARTIST,
          permissions: [ROLES.LIST],
        },
      },
      {
        id: MENU_IDS.menuArtistReward,
        title: "Phần thưởng",
        link: AppRoutes.artist_reward,
        icon: <RedeemIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGING_ARTIST_AWARDS,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuArtistsProductCategory,
        title: "Danh mục sản phẩm",
        link: AppRoutes.artistsProductCategories,
        icon: <CategoryIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_ARTIST_PRODUCT_CATEGORIES,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuGreetingCardLog,
        title: "Greeting Card Log",
        link: AppRoutes.greeting_card_log,
        icon: <CardGiftcardIcon />,
        type: "item",
        module: {
          code: MODULES.GREETING_CARD_LOG,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuAudio,
        title: "Audio",
        link: AppRoutes.audio,
        icon: <AudioFileIcon />,
        type: "item",
        module: {
          code: MODULES.AUDIO_MANAGEMENT,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuPresentArtist,
    title: "Quà tặng nghệ sĩ",
    link: AppRoutes.present_artist,
    icon: <RedeemIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuPresentArtist,
        title: "Quà tặng nghệ sĩ",
        link: AppRoutes.present_artist,
        icon: <PersonIcon />,
        type: "item",
        module: {
          code: MODULES.ARTIST_GIFT_LOG,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuPresentVideo,
        title: "Quà tặng video",
        link: AppRoutes.present_video,
        icon: <VideoLibraryOutlinedIcon />,
        type: "item",
        module: {
          code: MODULES.VIDEO_GIFT_LOG,
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuPresentAudio,
        title: "Quà tặng audio",
        link: AppRoutes.present_audio,
        icon: <AudioFileIcon />,
        type: "item",
        module: {
          code: MODULES.AUDIO_GIFT_LOG,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuAlbum,
    title: "Album",
    link: AppRoutes.album,
    icon: <AlbumIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_ALBUMS,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuAlbumVideo,
    title: "Album Video",
    link: AppRoutes.album_video,
    icon: <LibraryMusicIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_ALBUM_VIDEO,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuPublisher,
    title: "Nhà phân phối",
    link: AppRoutes.publishers,
    icon: <OtherHousesIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_PUBLISHER,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuProducts,
    title: "Sản phẩm",
    link: AppRoutes.products,
    icon: <Inventory2Icon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuProducts,
        title: "Sản phẩm",
        link: AppRoutes.products,
        icon: <InventoryIcon />,
        type: "item",
        module: {
          code: MODULES.PRODUCT_MANAGEMENT, //"product_management",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuProductCategory,
        title: "Danh mục sản phẩm",
        link: AppRoutes.productCategory,
        icon: <InventoryIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_ARTIST_PRODUCT_CATEGORIES, //"manage_product_categories",
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuEventTicket,
    title: "Quản lý sự kiện",
    link: AppRoutes.event_ticket,
    icon: <EventIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuEvent,
        title: "Sự kiện",
        link: AppRoutes.event,
        icon: <EventIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_EVENT, //"manage_event",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuEventArtist,
        title: "Sự kiện nghệ sĩ",
        link: AppRoutes.event_artist,
        icon: <EventNoteIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_EVENT_ARTIST, //"manage_event_artist",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuEventTicket,
        title: "Vé sự kiện",
        link: AppRoutes.event_ticket,
        icon: <ConfirmationNumberIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_EVENT_TICKET, //"manage_event_artist",
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    id: MENU_IDS.menuStatisticsArtists,
    title: "Thống kê",
    link: AppRoutes.statistics_artists,
    icon: <DashboardIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuVideoReport,
        title: "Thống kê video",
        link: AppRoutes.video_report,
        icon: <VideoLibraryIcon />,
        type: "item",
        module: {
          code: MODULES.VIDEO_STATISTICS,
          permissions: [ROLES.VIDEO_STATISTICS],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuAudioReport,
        title: "Thống kê audio",
        link: AppRoutes.audio_report,
        icon: <AudioFileIcon />,
        type: "item",
        module: {
          code: MODULES.AUDIO_STATISTICS,
          permissions: [ROLES.AUDIO_STATISTICS],
        },
      },
      // {
      //   isShow: true,
      //   id: MENU_IDS.menuVideoReport,
      //   title: "Thống kê bán hàng",
      //   link: AppRoutes.video_report,
      //   icon: <LayersIcon />,
      //   type: "item",
      //   module: {
      //     code: MODULES.VIDEO_STATISTICS,
      //     permissions: [ROLES.LIST],
      //   },
      // },
      // {
      //   isShow: true,
      //   id: MENU_IDS.menuStatisticsArtists,
      //   title: "Thống kê nghệ sĩ",
      //   link: AppRoutes.statistics_artists,
      //   icon: <LayersIcon />,
      //   type: "item",
      //   module: {
      //     code: MODULES.ARTIST_STATISTICS,
      //     permissions: [ROLES.LIST],
      //   },
      // },
      // {
      //   isShow: true,
      //   id: MENU_IDS.menuStatisticsSystem,
      //   title: "Thống kê hệ thống",
      //   link: AppRoutes.statistics_system,
      //   icon: <SettingsIcon />,
      //   type: "item",
      //   module: {
      //     code: MODULES.SYSTEM_STATISTICS,
      //     permissions: [ROLES.LIST],
      //   },
      // },
      {
        isShow: true,
        id: MENU_IDS.menuStatisticsPayment,
        title: "Thống kê mua gói",
        link: AppRoutes.statistics_premium_type_buy_report,
        icon: <SettingsIcon />,
        type: "item",
        module: {
          code: MODULES.PAYMENT_STATISTICS,
          permissions: [ROLES.PAYMENT_STATISTICS],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuHistoryUsedCoin,
        title: "Lịch sử dùng xu",
        link: AppRoutes.history_used_coin,
        icon: <HistoryIcon />,
        type: "item",
        module: {
          code: MODULES.PACKAGE_PURCHASE_HISTORY,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuEmojie,
    title: "Emojie",
    link: AppRoutes.emojie,
    icon: <AddReactionIcon />,
    type: "item",
    module: {
      code: MODULES.EMOJI_MANAGEMENT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuGifts,
    title: "Quà tặng",
    link: AppRoutes.gifts,
    icon: <CardGiftcardIcon />,
    type: "item",
    module: {
      code: MODULES.GIFT_MANAGEMENT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuOrder,
    title: "Đơn hàng",
    link: AppRoutes.order,
    icon: <ShoppingBagIcon />,
    type: "item",
    module: {
      code: MODULES.ORDER_MANAGEMENT,
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuPaymentType,
    title: "Phương thức thanh toán",
    link: AppRoutes.paymentType,
    icon: <PaymentIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_PAYMENT_METHODS, //"manage_payment_methods",
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuShippingUnit,
    title: "Đơn vị vận chuyển",
    link: AppRoutes.shippingUnit,
    icon: <LocalShippingIcon />,
    type: "item",
    module: {
      code: MODULES.MANAGE_SHIPPING_UNITS, //"manage_payment_methods",
      permissions: [ROLES.LIST],
    },
  },
  {
    isShow: true,
    id: MENU_IDS.menuService,
    title: "Quản lý dịch vụ",
    link: AppRoutes.service,
    icon: <LayersIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuService,
        title: "Dịch vụ",
        link: AppRoutes.service,
        icon: <LayersIcon />,
        type: "item",
        module: {
          code: MODULES.SERVICE_MANAGEMENT, //"service_management",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuPremiumType,
        title: "Gói dịch vụ",
        link: AppRoutes.premium_type,
        icon: <LayersIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_SERVICE_PACKAGES, //"manage_service_packages",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuHistoryPremium,
        title: "Lịch sử user mua gói",
        link: AppRoutes.history_premium,
        icon: <HistoryIcon />,
        type: "item",
        module: {
          code: MODULES.PACKAGE_PURCHASE_HISTORY, //"package_purchase_history",
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
  {
    isShow: true,
    id: MENU_IDS.menuConfigSystem,
    title: "Cấu hình hệ thống",
    link: AppRoutes.menuConfigSystem,
    icon: <SettingsIcon />,
    type: "collapse",
    children: [
      {
        isShow: true,
        id: MENU_IDS.menuConfigSystem,
        title: "Cấu hình",
        link: AppRoutes.configSystem,
        icon: <SettingsIcon />,
        type: "item",
        module: {
          code: MODULES.SYSTEM_CONFIGURATION, //"system_configuration",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuConfigHome,
        title: "Cấu hình Home",
        link: AppRoutes.configHome,
        icon: <SettingsIcon />,
        type: "item",
        module: {
          code: MODULES.CONFIGURE_THE_HOME_SCREEN, //"configure_the_home_screen",
          permissions: [ROLES.LIST],
        },
      },
      {
        isShow: true,
        id: MENU_IDS.menuAccountSystem,
        title: "Tài khoản Admin",
        link: AppRoutes.accoutSystem,
        icon: <ManageAccountsIcon />,
        type: "item",
        module: {
          code: MODULES.ADMIN_MANAGEMENT, //"admin_management",
          permissions: [ROLES.LIST],
        },
      },
      {
        id: MENU_IDS.permissionGroup,
        title: "Nhóm quyền",
        link: AppRoutes.permission_group,
        icon: <ShieldIcon />,
        type: "item",
        module: {
          code: MODULES.MANAGE_PERMISSION_GROUPS,
          permissions: [ROLES.LIST],
        },
      },
      {
        id: MENU_IDS.menuAuditLogs,
        title: "Audit logs",
        link: AppRoutes.audit_logs,
        icon: <HistoryIcon />,
        type: "item",
        module: {
          code: MODULES.AUDIT_LOGS_HISTORY,
          permissions: [ROLES.LIST],
        },
      },
      {
        id: MENU_IDS.menuLogSMShistory,
        title: "Log SMS",
        link: AppRoutes.log_sms_history,
        icon: <HistoryIcon />,
        type: "item",
        module: {
          code: MODULES.LOG_SMS_HISTORY,
          permissions: [ROLES.LIST],
        },
      },
    ],
  },
];

export default MENU_LEFT_CONFIG;
