import PropTypes from 'prop-types';
import { Modal as ModalAntd } from 'antd';
import classNames from 'classnames';

import './Modal.scss';

function Modal({ children, className, modalNotify, ...props }) {
  return (
    <ModalAntd
      {...props}
      maskClosable={false}
      className={classNames('custom-modal', {
        [className]: className,
        'modal-notify-body': modalNotify,
      })}
    >
      {children}
    </ModalAntd>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  modalNotify: PropTypes.bool,
};

export default Modal;
