const checkPermission = (module = {}, permissions = []) => {
  let totalPermission = permissions.length;
  let count = 0;
  permissions.forEach((i) => {
    const hasRole = module?.permissions?.find(
      (role) => role.permission_code === i
    );
    if (hasRole) {
      count++;
    }
  });
  return count === totalPermission;
};
export const checkPermissionList = (
  role_permissions,
  moduleCode,
  permissions
) => {
  const module = role_permissions.find((i) => i.code === moduleCode);
  if (module) {
    return checkPermission(module, permissions);
  } else {
    return false;
  }
};
